import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import WebFont from 'webfontloader';

// css
import "bootstrap/dist/css/bootstrap.min.css";
import './css/style.scss'

// components
import App from './components/App';

// action
import { client } from './actions';

WebFont.load({ google: { families: ['PT Serif:400,700', 'Open Sans:400,700'] } });


ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<Provider store={store}>
				<BrowserRouter basename={'/'}>
					<Switch>
						<Route exact path="/:name" component={App} />
					</Switch>
				</BrowserRouter>
			</Provider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
