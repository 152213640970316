import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
// images
// import signature from '../images/signature.png';

import { htmlDecode } from '../actions';

const Welcome = ({
    welcomeData: { 0: { guidebookTemplates: { templateWelcomeHeading, templateWelcomeImage, templateWelcomeName, templateWelcomeNameSignature, templateWelcomePersonalLetter, templateWelcomeTitle } } }
}) => {

    const [scriptNeeded, setScriptNeeded] = useState(false);
    useEffect(() => {
        if (!templateWelcomeNameSignature?.sourceUrl) {
            setScriptNeeded(true);
        }
    }, [templateWelcomeNameSignature]);

    return (
        <div className="container-fluid">
            <LazyLoad height={433}>
                <img id="hero-img" className="img-fluid" width="767" height="800" src={templateWelcomeImage?.sourceUrl} srcSet={templateWelcomeImage?.srcSet} alt={templateWelcomeImage?.altText} />
            </LazyLoad>
            <div id="content-container">
                <div id="content-box">
                    <div className="row">
                        <div className="col">
                            <h2>{templateWelcomeHeading}</h2>
                            <div dangerouslySetInnerHTML={{ __html: templateWelcomePersonalLetter }} />
                            <div id="signature-section" className="mt40">
                                {templateWelcomeNameSignature?.sourceUrl &&
                                    <img className="img-fluid mb10" src={templateWelcomeNameSignature?.sourceUrl} srcSet={templateWelcomeNameSignature?.srcSet} alt={templateWelcomeNameSignature?.altText} />
                                }
                                <p>
                                    <span className={`${scriptNeeded && 'cursive'} h1`}>{htmlDecode(templateWelcomeName)}</span ><br /><br />
                                    {templateWelcomeTitle}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome
