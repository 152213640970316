import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// actions
import { setStopNav } from '../slices/uiSlice';

const useDirection = ({
    navData,
    navItems,
    singleStopIndex,
    countStops,
}) => {
    const dispatch = useDispatch();

    const [navDir, setNavDir] = useState({
        prev: '',
        next: '',
    });

    useEffect(() => {
        const currentIndex = navItems.findIndex(item => item === navData);

        setNavDir({ prev: navItems[currentIndex - 1] ? navItems[currentIndex - 1] : '', next: navItems[currentIndex + 1] ? navItems[currentIndex + 1] : 'cover' });

        if (navData === 'singleStop') {
            if (singleStopIndex === countStops) {
                setNavDir({ prev: 'singleStop', next: 'review' });
            } else if (singleStopIndex === 0 && countStops > 0) {
                setNavDir({ prev: 'stops', next: 'singleStop' });
            } else {
                setNavDir({ prev: 'singleStop', next: 'singleStop' });
            }
        }
        navData === 'cover' && dispatch(setStopNav(false));
        navData === 'guideEmail' && setNavDir({ prev: 'cover', next: 'welcome' });
    }, [navData, singleStopIndex, navItems, countStops]);
    return navDir;
}

export default useDirection