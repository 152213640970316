import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useNavItems = ({
    guideReady,
    guideNeeded,
    emailNeeded,
    countStops,
    tipPage,
}) => {
    const { stopNav } = useSelector(state => state.ui);
    // check if email has been captured
    const emailCaptured = sessionStorage.getItem('emailCapture') ? JSON.parse(sessionStorage.getItem('emailCapture')) : null;
    const [navItems, setNavItems] = useState([]);

    useEffect(() => {
        const singleNeeded = [];
        !isNaN(countStops) && singleNeeded.push('singleStop');

        const tipNeeded = [];
        tipPage && tipNeeded.push('tip');

        const guideEmailNeeded = [];
        // guideNeeded is true then required
        // check if emailNeeded, not required
        guideNeeded && !guideReady && guideEmailNeeded.push('guideEmail');
        emailNeeded && emailCaptured === null && guideEmailNeeded.length !== 1 && guideEmailNeeded.push('guideEmail');

        const newNavItems = [
            'cover',
            ...guideEmailNeeded,
            'welcome',
            'tours',
            'guide',
            'stops',
            ...singleNeeded,
            'review',
            ...tipNeeded,
            'thankyou'
        ]
        setNavItems(newNavItems);
    }, [guideReady, countStops, tipPage, stopNav, emailCaptured, guideNeeded, emailNeeded]);


    return navItems;
}

export default useNavItems