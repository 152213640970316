import React, { useState, useEffect } from 'react'

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagramSquare, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

// actions
import { htmlDecode } from '../actions';

import styled from 'styled-components';

const GuidebookBar = styled('div')`
    background-color: ${props => props.secondaryColor ? props.secondaryColor : props.adminSecondaryColor ? props.adminSecondaryColor : 'white'};
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
`;
const HashTag = styled('p')`
    &:hover, &:focus{
        text-decoration: underline;
    }
`;

const Cover = ({
    secondaryColor,
    adminSecondaryColor,
    adminSocialLinks,
    logoBar,
    logoBarAdmin,
    logo,
    guidebookTitle,
    guidebookSocialHashtag,
    guidebookFeaturedImage,
}) => {

    const socialLinks = {
        facebookLink: {
            icon: faFacebook,
            link: adminSocialLinks?.facebookLink,
        },
        instagramLink: {
            icon: faInstagramSquare,
            link: adminSocialLinks?.instagramLink,
        },
        twitterLink: {
            icon: faTwitter,
            link: adminSocialLinks?.twitterLink,
        }
    }

    const copyInput = guidebookSocialHashtag;
    const [text, setText] = useState('');
    const [clicked, setClicked] = useState(false);

    let logoBarConditional = false;
    if (logoBar) {
        logoBarConditional = true;
    } else if (logoBarAdmin) {
        logoBarConditional = true;
    }

    useEffect(() => {
        if (clicked) {
            setTimeout(() => {
                setText('');
                setClicked(false);
            }, 1000);
        }
    }, [clicked]);



    return (
        <div className="container-fluid text-center">
            {logoBarConditional &&
                <GuidebookBar id="guidebook-logo" className="text-center" adminSecondaryColor={adminSecondaryColor} secondaryColor={secondaryColor}>
                    <img className="img-fluid" width="150" height="61" src={logo?.sourceUrl} alt={logo?.altText} />
                </GuidebookBar>
            }
            <img id="hero-img" className="img-fluid" width="767" height="800" src={guidebookFeaturedImage?.sourceUrl} srcSet={guidebookFeaturedImage?.srcSet} alt={guidebookFeaturedImage?.altText} />
            <div id="cover-container">
                <div className="row">
                    <div className="col">
                        <h2 className="mb20">{htmlDecode(guidebookTitle)}</h2>
                        {guidebookSocialHashtag &&
                            <React.Fragment>
                                <CopyToClipboard text={copyInput} onCopy={() => {
                                    setText('Hashtag Copied');
                                    setClicked(true);
                                }}>
                                    <HashTag className={`link-pointer mb0 ${clicked && 'link-clicked'}`}>#{guidebookSocialHashtag}</HashTag>
                                </CopyToClipboard>
                                {text !== '' &&
                                    <p className="has-small"><small>{text}</small></p>
                                }
                            </React.Fragment>
                        }
                        {/* <p><a className="social-link" href={`https://www.instagram.com/tags/${guidebookSocialHashtag}`} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faInstagramSquare} /></a></p> */}
                        <div className="row justify-content-center">
                            {Object.keys(socialLinks).map((item, index) =>
                                <React.Fragment key={`social-link-${item}`}>
                                    {adminSocialLinks[item] &&
                                        <div className="col-auto">
                                            <a className="social-link" href={socialLinks[item].link} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={socialLinks[item].icon} /></a>
                                        </div>
                                    }
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Cover
