import React from 'react';
import ReactGA from 'react-ga';


// actions
import { GAevent } from '../actions';


// images
import venmo from '../images/venmo.png';
import pay from '../images/chase-pay.png';
import zelle from '../images/zelle.png';
import payPal from '../images/paypal.png';
import cash from '../images/cash.png';

const Tip = ({
    templateTipImage,
    templateTipHeading,
    templateTipContent,
    templateTipsTipOptions,
    templateTipSuggestionsHeading,
    templateTips,
    templateGuideHeading
}) => {
    return (
        <div className="container-fluid">
            <img id="hero-img" className="img-fluid" width="767" height="800" src={templateTipImage?.sourceUrl} srcSet={templateTipImage?.srcSet} alt={templateTipImage?.altText} />
            <div id="content-container">
                <div className="content-box">
                    <div className="row mb20">
                        <div className="col">
                            <h2>{templateTipHeading}</h2>
                            {templateTipContent &&
                                <div dangerouslySetInnerHTML={{ __html: templateTipContent }} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-container pt0">
                <div className="content-box">
                    <div className="row">
                        <div className="col">
                            <h3 className="mb20">{templateTipSuggestionsHeading}</h3>
                            <div className="mb10">
                                {templateTipsTipOptions.venmoLink &&
                                    <div className="row">
                                        <div className="col">
                                            <a href={templateTipsTipOptions.venmoLink} className="btn btn-social btn-block btn-venmo mb10" target="_blank" rel="noreferrer noopener" onClick={() => {
                                                GAevent('User', 'Tip', 'Venmo');
                                                GAevent(templateGuideHeading, 'Tip', 'Venmo');
                                                ReactGA.outboundLink({ label: 'Tip Venmo' })
                                            }}><img className="img-fluid" src={venmo} alt="Venmo" /></a>
                                        </div>
                                    </div>
                                }
                                {templateTipsTipOptions.chasepayLink &&
                                    <div className="row">
                                        <div className="col">
                                            <a href={templateTipsTipOptions.chasepayLink} className="btn btn-social btn-block btn-pay mb10" target="_blank" rel="noreferrer noopener" onClick={() => {
                                                GAevent('User', 'Tip', 'Chase Pay');
                                                GAevent(templateGuideHeading, 'Tip', 'Chase Pay');
                                                ReactGA.outboundLink({ label: 'Tip Chase Pay' })
                                            }}><img className="img-fluid" src={pay} alt="Chase Pay" /></a>
                                        </div>
                                    </div>
                                }
                                {templateTipsTipOptions.zelleLink &&
                                    <div className="row">
                                        <div className="col">
                                            <a href={templateTipsTipOptions.zelleLink} className="btn btn-social btn-block btn-zelle mb10" target="_blank" rel="noreferrer noopener" onClick={() => {
                                                GAevent('User', 'Tip', 'Zelle');
                                                GAevent(templateGuideHeading, 'Tip', 'Zelle');
                                                ReactGA.outboundLink({ label: 'Tip Zelle' })
                                            }}><img className="img-fluid" src={zelle} alt="Zelle" /></a>
                                        </div>
                                    </div>
                                }
                                {templateTipsTipOptions.paypayLink &&
                                    <div className="row">
                                        <div className="col">
                                            <a href={templateTipsTipOptions.paypayLink} className="btn btn-social btn-block btn-paypal mb10" target="_blank" rel="noreferrer noopener" onClick={() => {
                                                GAevent('User', 'Tip', 'PayPal');
                                                GAevent(templateGuideHeading, 'Tip', 'PayPal');
                                                ReactGA.outboundLink({ label: 'Tip Paypal' })
                                            }}><img className="img-fluid" src={payPal} alt="PayPal" /></a>
                                        </div>
                                    </div>
                                }
                                {templateTipsTipOptions.cashLink &&
                                    <div className="row">
                                        <div className="col">
                                            <a href={templateTipsTipOptions.cashLink} className="btn btn-social btn-block btn-cash mb10" target="_blank" rel="noreferrer noopener" onClick={() => {
                                                GAevent('User', 'Tip', 'Cash');
                                                GAevent(templateGuideHeading, 'Tip', 'Cash');
                                                ReactGA.outboundLink({ label: 'Tip Cash' })
                                            }}><img className="img-fluid" src={cash} alt="Cash" /></a>
                                        </div>
                                    </div>
                                }
                            </div>
                            {templateTips && templateTips.map((item, index) =>
                                <React.Fragment key={`tip-${index}`}>
                                    <p className="mb0"><strong>{item.heading}</strong></p>
                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                    {index !== templateTips.length - 1 && <hr />}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tip
