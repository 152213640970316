import { configureStore } from '@reduxjs/toolkit';
import guidebookSlice from './components/slices/guidebookSlice';
import uiSlice from './components/slices/uiSlice';

export const store = configureStore({
  reducer: {
    data: guidebookSlice,
    ui: uiSlice
  },
});
