import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        allLoading: '',
        singleLoading: false,
        allGuides: false,
        stopNav: false,
        ga: {
            lastPage: '0 - cover',
        },
    },
    reducers: {
        isLoading: (state, action) => {
            state[action.payload.type] = action.payload.value;
        },
        gaPageUpdate: (state, action) => {
            let pageArray = ['cover', 'welcome', 'tours', 'guide', 'stops', 'review', 'tip', 'thankyou'];
            let newPageIndex = pageArray.findIndex(pageItem => pageItem == action.payload);
            let page = state.ga.lastPage.split('-')[1].split(' ')[1];
            let pageIndex = pageArray.findIndex(pageItem => pageItem == page);
            if (page !== action.payload) {
                if (newPageIndex > pageIndex) {
                    state.ga.lastPage = `${newPageIndex} - ${action.payload}`;
                }
            }
        },
        setStopNav: (state, action) => {
            state.stopNav = action.payload;
        }
    },
});

export const { isLoading, gaPageUpdate, setStopNav } = uiSlice.actions;
export const selectUi = state => state.ui;
export default uiSlice.reducer;
