import { createSlice } from '@reduxjs/toolkit';

export const guidebookSlice = createSlice({
  name: 'data',
  initialState: {
    guidebooks: [],
    admin: {},
    guides: [],
    currentGuide: {},
  },
  reducers: {
    getSingleGuidebook: (state, action) => {
      let index = state.guidebooks?.indexOf(guidebook => guidebook.guidebooksId === action.payload.guidebooksId);
      if (index === -1) {
        state.guidebooks = [...state.guidebooks, action.payload.guidebooks];
      }
    },
    getAllGuidebooks: (state, action) => {
      state.guidebooks = action.payload;
    },
    getAdmin: (state, action) => {
      state.admin = action.payload;
    },
    getAllGuides: (state, action) => {
      state.guides = action.payload;
    },
    getCurrentGuide: (state, action) => {
      state.currentGuide = action.payload;
    }
  },
});

export const { getSingleGuidebook, getAllGuidebooks, getAdmin, getAllGuides, getCurrentGuide } = guidebookSlice.actions;
export const selectGuidebook = state => state.guidebooks;
export default guidebookSlice.reducer;
