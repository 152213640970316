import React from 'react';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronRight, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

// actions
import { GAevent } from '../../actions';

import styled from 'styled-components';
const NavBarStyled = styled.div`
background-color: ${props => props.primaryColor ? props.primaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : 'blue'};
padding: 15px;
border-top: 2px solid #fff;
`;

const NavBar = ({
    navData,
    navDir,
    guideReady,
    primaryColor,
    adminPrimaryColor,

    setNavData,
    setDirection,
    menuAction,
    singleTimeout,
}) => {

    // check Data loaded
    const { stopNav } = useSelector(state => state.ui);


    return (
        <NavBarStyled id="nav-bar" adminPrimaryColor={adminPrimaryColor} primaryColor={primaryColor}>
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="btns col" onClick={() => {
                        if (navData !== 'cover') {
                            setDirection('left');
                            setNavData(navDir.prev);
                            singleTimeout('left');
                            GAevent('User', 'Nav Bar', 'Left');
                        }
                    }}>
                        {navData !== 'cover' &&
                            <FontAwesomeIcon icon={faChevronLeft} />
                        }
                    </div>

                    <div className="btns col" onClick={() => {
                        if (guideReady && !stopNav) { menuAction() }
                    }}>
                        {guideReady && !stopNav &&
                            <FontAwesomeIcon icon={faBars} />
                        }
                    </div>
                    <div className="btns col" onClick={() => {
                        if (!stopNav) {
                            setDirection('right');
                            setNavData(navDir.next);
                            singleTimeout('right');
                            GAevent('User', 'Nav Bar', 'Right');
                        }
                    }}>
                        {!stopNav &&
                            <FontAwesomeIcon icon={faChevronRight} />
                        }
                    </div>
                </div>
            </div>
        </NavBarStyled>
    )
}

export default NavBar
