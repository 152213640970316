import React from 'react';

// actions
import { htmlDecode, GAevent } from '../actions';

const Iframe = ({
    guidebookMap
}) => {

    return (
        <div className="embed-responsive embed-responsive-16by9 tour-stop">
            <iframe className="embed-responsive-item" width="700" height="600" src={guidebookMap} title="Map" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    )
}

const TourStops = ({
    guidebookFeaturedImage,
    guidebookHeading,
    guidebookDescription,
    guidebookStops,
    guidebookMapOrImg,
    guidebookMap,
    guidebookMapImg,
    guidebookImgLink,

    singleStopClick,
    setDirection
}) => {
    return (
        <div className="container-fluid">
            <img id="hero-img" className="img-fluid mb20" width="767" height="1000" src={guidebookFeaturedImage?.sourceUrl} srcSet={guidebookFeaturedImage?.srcSet} alt={guidebookFeaturedImage?.altText} />
            <div id="content-container" className="featured-image-full">
                <div id="content-box">
                    <div className="row">
                        <div className="col">
                            <h2>{htmlDecode(guidebookHeading)}</h2>
                            <p dangerouslySetInnerHTML={{ __html: guidebookDescription }} />
                        </div>
                    </div>
                </div>
            </div>
            {guidebookStops?.map((item, index) =>
                <div className="content-container w-img single-stop" onClick={() => {
                    setDirection('right');
                    setTimeout(() => {
                        GAevent('User', 'Single Stop', htmlDecode(item.guidebookStops.stopsPreviewHeading));
                        singleStopClick(index);
                    }, 100);
                }} key={`stops-${index}`}>
                    <img className="img-fluid" src={item.guidebookStops.stopsPreviewImage.smallUrl} srcSet={item.guidebookStops.stopsPreviewImage?.smallSrcSet} alt={item.guidebookStops.stopsPreviewImage?.altText} />
                    <div className="content-box">
                        <div className="row">
                            <div className="col">
                                <h3>{htmlDecode(item.guidebookStops.stopsPreviewHeading)}</h3>
                                {item?.guidebookStops?.stopsPreviewDescription && <p>{item.guidebookStops.stopsPreviewDescription}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {guidebookMapOrImg === 'map' ?
                <Iframe guidebookMap={guidebookMap} />
                : guidebookMapOrImg === 'img' &&
                <>
                    {guidebookImgLink ?
                        <a href={guidebookImgLink} target="_blank" rel="noreferrer noopener">
                            <img className="img-fluid" width="767" height="1000" src={guidebookMapImg?.sourceUrl} srcSet={guidebookMapImg?.srcSet} alt={guidebookMapImg?.altText} />
                        </a>
                        :
                        <img className="img-fluid" width="767" height="1000" src={guidebookMapImg?.sourceUrl} srcSet={guidebookMapImg?.srcSet} alt={guidebookMapImg?.altText} />
                    }
                </>
            }
        </div>
    )
}

export default TourStops
