export const components = {
    initial: { opacity: 1, x: 150 },
    initialLeft: { opacity: 1, x: -150 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 1, x: -150 },
    exitLeft: { opacity: 1, x: 150 }
}

export const menu = {
    initial: { opacity: 0, y: -100 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -100 }
}

export const sendFriend = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
}

export const googleLang = {
    initial: {
        y: `50%`,
        x: 177,
    },
    animate: {
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.25
        }
    },
    animateExit: {
        x: 177,
        transition: {
            type: "spring",
            bounce: 0.25
        }
    }
}