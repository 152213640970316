import React from 'react';
import { motion } from 'framer-motion';

const Loading = () => {
    return (
        <motion.div key="loading" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0.5 }}>
            <div id="loading">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </motion.div>
    )
}

export default Loading
