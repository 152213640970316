import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebook, faYelp } from "@fortawesome/free-brands-svg-icons";

// actions
import { GAevent } from '../actions';

import styled from 'styled-components';
const GuidebookBar = styled('div')`
    background-color: ${props => props.secondaryColor ? props.secondaryColor : props.adminSecondaryColor ? props.adminSecondaryColor : 'white'};
`;

const Review = ({
    logo,
    secondaryColor,
    adminSecondaryColor,
    reviewData: { 0: { guidebookTemplates: { templateReviewHeading, templateReviewDescription, templateReviewHeader, reviewLinks } } },
    adminOptions: { adminReviewLinks, adminReviewHeader },

}) => {

    const { templateGuideHeading } = useSelector(state => state.data.currentGuide);


    const links = {
        facebookReviewLink: reviewLinks.facebookReviewLink ? reviewLinks.facebookReviewLink : adminReviewLinks.facebookReviewLink,
        googleReviewLink: reviewLinks.googleReviewLink ? reviewLinks.googleReviewLink : adminReviewLinks.googleReviewLink,
        tripAdvisorReviewLink: reviewLinks.tripAdvisorReviewLink ? reviewLinks.tripAdvisorReviewLink : adminReviewLinks.tripAdvisorReviewLink,
        yelpReviewLink: reviewLinks.yelpReviewLink ? reviewLinks.yelpReviewLink : adminReviewLinks.yelpReviewLink,
    };
    const linkItems = {
        facebookReviewLink: { btn: 'btn-facebook', icon: faFacebook, text: 'Facebook' },
        googleReviewLink: { btn: 'btn-google', icon: faGoogle, text: 'Google' },
        tripAdvisorReviewLink: { btn: 'btn-trip', icon: '', text: 'Tripadvisor' },
        yelpReviewLink: { btn: 'btn-yelp', icon: faYelp, text: 'Yelp' },
    };

    const linkHeader = templateReviewHeader ? templateReviewHeader : adminReviewHeader;

    const [showLinksSection, setShowLinksSection] = useState(true);

    useEffect(() => {
        let showLink = false;
        let hasLinks = Object.keys(links).map((item, index) => {
            if (links[item]) {
                return true;
            } else {
                return false;
            }
        });
        if (linkHeader) {
            showLink = true;
        } else if (hasLinks.includes(true)) {
            showLink = true;
        }
        setShowLinksSection(showLink);
    }, [linkHeader, links]);

    return (
        <div className="container-fluid">
            <GuidebookBar id="guidebook-logo" className="text-center" adminSecondaryColor={adminSecondaryColor} secondaryColor={secondaryColor}>
                <img className="img-fluid" width="150" height="61" src={logo?.sourceUrl} alt={logo?.altText} />
            </GuidebookBar>
            <div className="content-container">
                <div className="content-box">
                    <div className="row">
                        <div className="col">
                            <h2>{templateReviewHeading}</h2>
                            <div dangerouslySetInnerHTML={{ __html: templateReviewDescription }} />
                        </div>
                    </div>
                </div>
            </div>
            {showLinksSection &&
                <div className="content-container pt0">
                    <div className="content-box">
                        {linkHeader &&
                            <div className="row">
                                <div className="col">
                                    <h3>{linkHeader}</h3>
                                </div>
                            </div>
                        }
                        {Object.keys(links).map((item, index) =>
                            <React.Fragment key={`review-links-${item}`}>
                                {links[item] &&
                                    <div className="row">
                                        <div className="col">
                                            <a href={links[item]} className={`btn btn-social btn-block ${linkItems[item].btn} mb10`} target="_blank" rel="noreferrer noopener" onClick={() => {
                                                GAevent('User', 'Review', linkItems[item].text);
                                                GAevent(templateGuideHeading, 'Review', linkItems[item].text);
                                                ReactGA.outboundLink({ label: 'Review Links' })
                                            }}>
                                                {linkItems[item].icon !== '' && <FontAwesomeIcon icon={linkItems[item].icon} className="mr5" />}
                                                {linkItems[item].text}
                                            </a>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default Review
