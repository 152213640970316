import { ApolloClient, InMemoryCache } from '@apollo/client';
import ReactGA from 'react-ga';

export const acfImage = (src, size) => {
    if (src !== undefined) {
        let srces = src.split(',');
        let image;
        srces.map(sizes => {
            if (sizes.includes(size)) { image = sizes.split(size)[0]; }
            return true;
        });
        return image;
    }
}

export const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API}/graphql?query=`,
    shouldBatch: true,
    cache: new InMemoryCache()
})

export const imgSize = (size) => {
    console.log(size);
    const array = size.split(',');
    return array;
}

export const htmlDecode = (input) => {
    const e = document.createElement('div');
    e.innerHTML = input;
    return e.innerText;
}


export const GAevent = (categoryName, eventName, labelName) => {
    ReactGA.event({
        category: categoryName,  // Required
        action: eventName,       // Required
        label: labelName,
        value: 10,
        nonInteraction: false
    });
}