import React from 'react';
import { Helmet } from 'react-helmet';

// actions
import { htmlDecode } from '../../actions';

const SEO = ({
    title,
    favicon,
    metaDesc
}) => {
    return (
        <Helmet>
            <title>{htmlDecode(title)}</title>
            <meta name="description" content={htmlDecode(metaDesc)} />
            <meta property="og:description" content={htmlDecode(metaDesc)} />
            {favicon?.sourceUrl &&
                <link rel="icon" href={favicon?.sourceUrl} />
            }
        </Helmet>
    )
}

export default SEO
