import React from 'react'

const Guide = ({
    guideData: { templateGuideImage, templateGuideHeading, templateGuideDescription, templateGuideFavorites }
}) => {
    return (
        <div className="container-fluid">
            <img id="hero-img" className="img-fluid" width="767" height="800" src={templateGuideImage?.sourceUrl} srcSet={templateGuideImage?.srcSet} alt={templateGuideImage?.altText} />
            <div id="content-container">
                <div id="content-box">
                    <div className="row mb20">
                        <div className="col">
                            <h2>{templateGuideHeading}</h2>
                            <div dangerouslySetInnerHTML={{ __html: templateGuideDescription }} />
                        </div>
                    </div>
                    {templateGuideFavorites?.map((item, index) =>
                        <React.Fragment key={`guide-favorites-${index}`}>
                            {item.description &&
                                <div className="row mb20">
                                    <div className="col">
                                        <h3>{item.heading}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Guide
