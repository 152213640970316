import React from 'react';

// actions
import { htmlDecode } from '../actions';

const Tours = ({
    tourData: { 0: { guidebookTemplates: { templateTourImage, templateTourHeading, templateTourDescription, templateTourFaqs } } }
}) => {
    return (
        <div className="container-fluid">
            <img id="hero-img" className="img-fluid" width="767" height="800" src={templateTourImage?.sourceUrl} srcSet={templateTourImage?.srcSet} alt={templateTourImage?.altText} />
            <div id="content-container">
                <div id="content-box">
                    <div className="row mb20">
                        <div className="col">
                            <h1>{htmlDecode(templateTourHeading)}</h1>
                            <div dangerouslySetInnerHTML={{ __html: templateTourDescription }} />
                        </div>
                    </div>
                    {templateTourFaqs && templateTourFaqs.map((item, index) =>
                        <div className="row mb20" key={`tour-faq-${index}`}>
                            <div className="col">
                                <h3>{htmlDecode(item.question)}</h3>
                                <p>{htmlDecode(item.answer)}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Tours
