import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { components, googleLang } from '../actions/motion';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/pro-solid-svg-icons";


// components
import SEO from './utilities/SEO';
import Cover from './Cover';
import GuestEmail from './GuestEmail';
import Welcome from './Welcome';
import Tours from './Tours';
import Guide from './Guide';
import TourStops from './TourStops';
import SingleStop from './SingleStop';
import Review from './Review';
import Tip from './Tip';
import ThankYou from './ThankYou';
import MenuNav from './utilities/MenuNav';
import NavBar from './utilities/NavBar';
import Loading from './utilities/Loading';
import useNavItems from './utilities/useNavItems';
import useDirection from './utilities/useDirection';

// action
import { getSingleGuidebook, getAdmin, getAllGuides, getCurrentGuide } from './slices/guidebookSlice';
import { isLoading, gaPageUpdate, setStopNav } from './slices/uiSlice';
import { htmlDecode, GAevent } from '../actions';

// JSON
import { singleGuidebooks, adminSettings, allGuides, allGuidebooks } from '../JSON/WPData';

import styled from 'styled-components';
const AppContainerStyled = styled.div`
	background-color: ${props => props.guidebookPrimaryColor ? props.guidebookPrimaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : 'blue'};
`;

const GoogleBtn = styled.div`
	background-color: ${props => props.guidebookPrimaryColor ? props.guidebookPrimaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : 'blue'};
`;

function App() {
	const location = useLocation();
	const dispatch = useDispatch();

	// should app load
	const [loadApp, setLoadApp] = useState(false);

	// check Data loaded
	const { stopNav, singleLoading: loaded, allGuides: guidesLoaded, ga: { lastPage } } = useSelector(state => state.ui);

	// Admin Data
	const [adminOptions, setAdminOptions] = useState({});
	const [adminTagLine, setAdminTagLine] = useState('');

	// Guidebook Data
	const [guidebookData, setGuidebookData] = useState({});
	const [menuItems, setMenuItems] = useState({});

	// Guide Data
	const { guides } = useSelector(state => state.data);
	const [guidesData, setGuidesData] = useState({});
	const [inputGuide, setInputGuide] = useState(sessionStorage.getItem('guideId') ? JSON.parse(sessionStorage.getItem('guideId')) : null);
	const [inputGuideError, setInputGuideError] = useState('');
	const [guideReady, setGuideReady] = useState(false);

	// pull admin settings
	const { data: dataAdmin } = useQuery(adminSettings, {
		onCompleted: () => {
			dispatch(getAdmin({ ...dataAdmin.guidebookOptions.guidebookOptions }));
			setAdminOptions({ ...dataAdmin.guidebookOptions.guidebookOptions });
			setAdminTagLine(dataAdmin.generalSettings.description);
		}
	});

	// pull single guidebook
	const url = location.pathname.split('/')[1];
	const { data: dataSingle } = useQuery(singleGuidebooks, {
		variables: { url },
		onCompleted: () => {
			dispatch(getSingleGuidebook({ ...dataSingle.guidebooks }));
			setGuidebookData({ ...dataSingle.guidebooks.guidebooks });
			dispatch(isLoading({ type: 'singleLoading', value: true }));
		},
	});

	// pull all guides
	const { data: dataGuide } = useQuery(allGuides, {
		onCompleted: () => {
			dispatch(getAllGuides([...dataGuide.allGuidebookGuides.nodes]));
			dispatch(isLoading({ type: 'allGuides', value: true }));
		},
	});

	const [navData, setNavData] = useState('cover');
	const [singleStopData, setSingleStopData] = useState({});
	const [singleStopIndex, setSingleStopIndex] = useState(0);
	const [singleStopTransition, setSingleStopTransition] = useState(false);

	const singleStopClick = (id) => {
		setSingleStopData({ ...guidebookData?.guidebookStops[id]?.guidebookStops })
		setSingleStopIndex(id);
		setNavData('singleStop');
		singleStopDirection(id, 'start');
	}

	const [menu, setMenu] = useState(false);
	const [direction, setDirection] = useState('right');
	const menuAction = () => {
		setMenu(prev => prev === false ? true : false);
	}

	const singleStopDirection = (currentStop, direction) => {
		const numStops = guidebookData?.guidebookStops?.length - 1;
		const conditional = direction === 'right' ? currentStop + 1 : direction === 'start' ? currentStop : currentStop - 1;
		if ((direction === 'right') && (currentStop === numStops)) {
			setNavData('review');
		} else if ((direction === 'left' && currentStop === 0)) {
			setNavData('stops');
		} else {
			const stops = guidebookData?.guidebookStops ? guidebookData?.guidebookStops[conditional].guidebookStops ? guidebookData?.guidebookStops[conditional].guidebookStops : null : null;
			if (stops) {
				setSingleStopTransition(false);
				setTimeout(() => {
					setSingleStopTransition(true);
					setSingleStopData({ ...stops })
					setSingleStopIndex(conditional);
					setNavData('singleStop');
				}, 300);
			} else {
				setNavData('review');
			}
		}
	};

	// useNavItems will decide what items are in the nav
	const navItems = useNavItems({
		guideReady,
		guideNeeded: guidebookData?.guidebookGuideid,
		emailNeeded: guidebookData?.guidebookGuideEmailFormId,
		countStops: guidebookData?.guidebookStops?.length - 1,
		tipPage: guidebookData?.guidebookTipPage,
	});

	// useDirection figures out what is the next or prev
	const navDir = useDirection({
		navData,
		singleStopIndex,
		navItems,
		countStops: guidebookData?.guidebookStops?.length - 1,
	});

	const singleTimeout = (dir) => {
		setTimeout(() => {
			navData === 'singleStop' ?
				singleStopDirection(singleStopIndex, dir)
				: dir === 'right' && navData === 'stops' && singleStopDirection(0, 'start');
		}, 100);
	};

	const swipe = useSwipeable({
		onSwipedLeft: () => {
			setDirection('right');
			!stopNav && setNavData(navDir.next);
			stopNav && navData === 'cover' && setNavData(navDir.next);
			singleTimeout('right');
		},
		onSwipedRight: () => {
			setDirection('left');
			navData !== 'cover' && setNavData(navDir.prev);
			singleTimeout('left');
		},
		preventDefaultTouchmoveEvent: true,
		trackMouse: true
	});

	useEffect(() => {
		dispatch(gaPageUpdate(navData));
		window.scrollTo(0, 0);
	}, [navData]);

	useEffect(() => {

		let menu = {};
		if (Object.keys(guidebookData).length > 0) {
			const coverNav = guidebookData?.coverNav ? guidebookData?.coverNav : 'Cover';
			const welcomeNav = guidebookData?.guidebookWelcome[0].guidebookTemplates.templateWelcomeNav ? guidebookData?.guidebookWelcome[0].guidebookTemplates.templateWelcomeNav : guidebookData?.guidebookWelcome[0].guidebookTemplates.templateWelcomeHeading;
			const toursNav = guidebookData?.guidebookTodaysTour[0].guidebookTemplates.templateTourNav ? guidebookData?.guidebookTodaysTour[0].guidebookTemplates.templateTourNav : guidebookData?.guidebookTodaysTour[0].guidebookTemplates.templateTourHeading;
			const stopNav = guidebookData?.stopNav ? guidebookData?.stopNav : guidebookData?.guidebookHeading;
			const reviewNav = guidebookData?.guidebookLeaveReview[0].guidebookTemplates.templateReviewNav ? guidebookData?.guidebookLeaveReview[0].guidebookTemplates.templateReviewNav : guidebookData?.guidebookLeaveReview[0].guidebookTemplates.templateReviewHeading;
			const closeNav = guidebookData?.guidebookTourClose[0].guidebookTemplates.templateCloseNav ? guidebookData?.guidebookTourClose[0].guidebookTemplates.templateCloseNav : guidebookData?.guidebookTourClose[0].guidebookTemplates.templateCloseHeading;
			menu = {
				cover: htmlDecode(coverNav),
				welcome: htmlDecode(welcomeNav),
				tours: htmlDecode(toursNav),
				guide: '',
				stops: htmlDecode(stopNav),
				review: htmlDecode(reviewNav),
				tip: '',
				close: htmlDecode(closeNav),
			};
		}

		// Check if you need to set a Guide
		if (guidebookData?.guidebookGuideid === true && guidesLoaded) {
			if (inputGuide !== null) {
				let guideIndex = '';
				guides.map((guide, index) => {
					let inputCheck = inputGuide.toLowerCase();
					let guideId = guide.guidebookGuide?.templateGuideId?.toLowerCase();
					let guideName = guide.guidebookGuide?.templateGuideHeading?.toLowerCase();
					if (guideId === inputCheck || guideName === inputCheck) {
						guideIndex = index;
						return index;
					}
					return false;
				});
				if (guideIndex !== '') {
					const tipNav = guidebookData?.templateTipNav ? guidebookData?.templateTipNav : guides[guideIndex].guidebookGuide.templateTipHeading ? guides[guideIndex].guidebookGuide.templateTipHeading : 'Tip';
					const guideNav = guidebookData?.guideNav ? guidebookData?.guideNav : guides[guideIndex].guidebookGuide.templateGuideHeading;
					menu.guide = htmlDecode(guideNav);
					menu.tip = htmlDecode(tipNav);
					sessionStorage.setItem('guideId', JSON.stringify(`${inputGuide}`));
					setInputGuideError('');
					setGuidesData(guides[guideIndex].guidebookGuide);
					dispatch(getCurrentGuide(guides[guideIndex].guidebookGuide));
					setGuideReady(true);
					setStopNav(false);
				} else {
					setInputGuideError('This code is probably wrong. Input new code.');
				}
			}
			setLoadApp(true);
		} else if (loaded && !guidebookData?.guidebookGuideid) {
			const tipNav = guidebookData?.templateTipNav ? guidebookData?.templateTipNav : guidebookData?.guidebookTourGuide[0]?.guidebookGuide?.templateTipHeading ? guidebookData?.guidebookTourGuide[0]?.guidebookGuide?.templateTipHeading : 'Tip';
			const guideNav = guidebookData?.guideNav ? guidebookData?.guideNav : guidebookData?.guidebookTourGuide[0].guidebookGuide.templateGuideHeading;
			setGuidesData(guidebookData.guidebookTourGuide[0].guidebookGuide);
			dispatch(getCurrentGuide(guidebookData.guidebookTourGuide[0].guidebookGuide));
			menu.guide = htmlDecode(guideNav);
			menu.tip = htmlDecode(tipNav);
			setLoadApp(true);
			setGuideReady(true);
		}

		// set menu items after guide check
		setMenuItems(menu);

	}, [loaded, guidesLoaded, guidebookData, guides, inputGuide]);

	// GA
	ReactGA.initialize('UA-212295308-1',
		{
			gaOptions: {
				siteSpeedSampleRate: 100,
				alwaysSendReferrer: true,
			}
		},
	);
	useEffect(() => {
		ReactGA.pageview(`${location.pathname}`); // hit page view once

		// Page Loading speeds
		if (window.performance) {
			const timeSincePageLoad = Math.round(performance.now());

			// Sends the timing hit to Google Analytics.
			ReactGA.timing({
				category: 'Page Load Time',
				action: 'Loading',
				variable: 'load',
				value: timeSincePageLoad, // in milliseconds
				label: window.location.pathname
			});
		}
	}, []);


	useEffect(() => {
		GAevent('User', 'Last Page', lastPage);
		if (guidesData.templateGuideHeading !== undefined) {
			GAevent(guidesData.templateGuideHeading, 'Last Page', lastPage);
		}
	}, [lastPage, guidesData]);

	// GA for all guidebooks
	const { data: dataAllGuidebooks } = useQuery(allGuidebooks, {
		onCompleted: () => {
			GAevent('Num Guidebooks', adminTagLine, dataAllGuidebooks.allGuidebooks.nodes.length);
		}
	});


	const [googleLangBtn, setGoogleLangBtn] = useState(false);
	const googleTranslateElementInit = () => {
		new window.google.translate.TranslateElement({
			pageLanguage: 'en',
			autoDisplay: false, layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT
		}, 'google_translate_element')
	}

	useEffect(() => {
		if (adminOptions.adminGoogletranslate) {
			// google translate
			let addScript = document.createElement('script');
			addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
			document.body.appendChild(addScript);
			window.googleTranslateElementInit = googleTranslateElementInit;
		}
	}, [loaded]);

	return (
		<div id="swipe-container" {...swipe}>
			<AnimatePresence initial={false}>
				{!loadApp ?
					< Loading />
					:
					<>
						<Helmet>
							<style type="text/css">{`
								a { color: ${guidebookData?.guidebookPrimaryColor ? guidebookData?.guidebookPrimaryColor : adminOptions.adminPrimaryColor};}
								a:hover { color: ${guidebookData?.guidebookPrimaryColor ? guidebookData?.guidebookPrimaryColor : adminOptions.adminPrimaryColor};}
							`}</style>
						</Helmet>
						<SEO title={`${guidebookData?.guidebookTitle} - ${adminTagLine}`} favicon={adminOptions.adminFav} metaDesc={guidebookData?.guidebookTitle} />
						{adminOptions.adminGoogletranslate &&
							<motion.div id="google-section" initial={'initial'} animate={googleLangBtn ? 'animate' : 'animateExit'} variants={googleLang}>
								<div className="row no-gutters">
									<div className="col-auto">
										<GoogleBtn id="google-btn" adminPrimaryColor={adminOptions.adminPrimaryColor} guidebookPrimaryColor={guidebookData?.guidebookPrimaryColor} onClick={() =>
											setGoogleLangBtn(prev => !prev)
										}><FontAwesomeIcon icon={faLanguage} /></GoogleBtn>
									</div>
									<div className="col">
										<div id="google_translate_element"></div>
									</div>
								</div>
							</motion.div>
						}
						<MenuNav menu={menu} menuItems={menuItems} guideReady={guideReady} adminPrimaryColor={adminOptions.adminPrimaryColor} primaryColor={guidebookData?.guidebookPrimaryColor} logo={guidebookData?.guidebookLogo} tipPage={guidebookData?.guidebookTipPage} menuAction={menuAction} setNavData={setNavData} setMenu={setMenu} />
						<AppContainerStyled id="app-container" adminPrimaryColor={adminOptions.adminPrimaryColor} guidebookPrimaryColor={guidebookData?.guidebookPrimaryColor}>
							<AnimatePresence inital={true} exitBeforeEnter>
								{navData === 'cover' &&
									<motion.div id="cover" key="cover" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<Cover
											guideReady={guideReady}
											inputGuideError={inputGuideError}
											adminSecondaryColor={adminOptions.adminSecondaryColor}
											secondaryColor={guidebookData?.guidebookSecondaryColor}
											logoBar={guidebookData?.guidebookLogobar}
											logoBarAdmin={adminOptions.adminLogobar}
											logo={guidebookData?.guidebookLogo}
											guidebookTitle={guidebookData?.guidebookTitle}
											guidebookSocialHashtag={guidebookData?.guidebookSocialHashtag}
											guidebookFeaturedImage={guidebookData?.guidebookFeaturedImage}
											adminSocialLinks={adminOptions.adminSocialLinks}
											setInputGuide={setInputGuide}
										/>
									</motion.div>
								}
								{navData === 'guideEmail' &&
									<motion.div id="guideEmail" key="guideEmail" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<GuestEmail
											guidebookData={guidebookData}
											guideReady={guideReady}
											inputGuideError={inputGuideError}
											adminSecondaryColor={adminOptions.adminSecondaryColor}
											secondaryColor={guidebookData?.guidebookSecondaryColor}
											setInputGuide={setInputGuide}
											setNavData={setNavData}
										/>
									</motion.div>
								}
								{navData === 'welcome' &&
									<motion.div id="welcome" key="welcome" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<Welcome welcomeData={guidebookData?.guidebookWelcome} />
									</motion.div>
								}
								{navData === 'tours' &&
									<motion.div id="tours" key="tours" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<Tours tourData={guidebookData?.guidebookTodaysTour} />
									</motion.div>
								}
								{navData === 'guide' &&
									<motion.div id="guide" key="guide" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<Guide guideData={guidesData} />
									</motion.div>
								}
								{navData === 'stops' &&
									<motion.div id="tour-stops" key="tour-stops" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<TourStops
											guidebookFeaturedImage={guidebookData?.guidebookStopsFeaturedImage}
											guidebookHeading={guidebookData?.guidebookHeading}
											guidebookDescription={guidebookData?.guidebookDescription}
											guidebookStops={guidebookData?.guidebookStops}
											guidebookMapOrImg={guidebookData?.mapOrImage}
											guidebookMap={guidebookData?.stopMap}
											guidebookMapImg={guidebookData?.stopMapImg}
											guidebookImgLink={guidebookData?.stopMapImgLink}
											singleStopClick={singleStopClick}
											setDirection={setDirection}
										/>
									</motion.div>
								}
								{(navData === 'singleStop' && singleStopTransition) &&
									<motion.div id="single-stops" key="single-stops" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<SingleStop singleStopData={singleStopData} />
									</motion.div>
								}
								{navData === 'review' &&
									<motion.div id="review" key="review" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<Review
											logo={guidebookData?.guidebookLogo}
											adminOptions={adminOptions}
											reviewData={guidebookData?.guidebookLeaveReview}
											adminSecondaryColor={adminOptions.adminSecondaryColor}
											secondaryColor={guidebookData?.guidebookSecondaryColor} />
									</motion.div>
								}
								{navData === 'tip' &&
									<motion.div id="tip" key="tip" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<Tip
											templateTipHeading={guidesData?.templateTipHeading ? guidesData?.templateTipHeading : 'Tip'}
											templateTipContent={guidesData?.templateTipContent}
											templateTipImage={guidesData?.templateGuideImage}
											templateTipsTipOptions={guidesData?.templateTipsTipOptions}
											templateTipSuggestionsHeading={guidebookData?.templateTipSuggestionsHeading}
											templateTips={guidebookData?.templateTips}
											templateGuideHeading={guidebookData?.templateGuideHeading}
										/>
									</motion.div>
								}
								{navData === 'thankyou' &&
									<motion.div id="thank-you" key="thank-you" initial={direction === 'right' ? 'initial' : 'initialLeft'} animate={'animate'} exit={direction === 'right' ? 'exit' : 'exitLeft'} variants={components} transition={{ duration: 0.3, type: "tween" }}>
										<ThankYou thankData={guidebookData?.guidebookTourClose} />
									</motion.div>
								}
							</AnimatePresence>
						</AppContainerStyled>
						<NavBar
							navData={navData}
							navDir={navDir}
							guideReady={guideReady}
							primaryColor={guidebookData?.guidebookPrimaryColor}
							adminPrimaryColor={adminOptions.adminPrimaryColor}
							singleStopIndex={singleStopIndex}
							setNavData={setNavData}
							setDirection={setDirection}
							menuAction={menuAction}
							singleTimeout={singleTimeout}
						/>
					</>
				}
			</AnimatePresence>
		</div>
	);
}

export default App;
