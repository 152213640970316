import React from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { menu as menuMotion } from '../../actions/motion';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

// actions
import { GAevent } from '../../actions';
import { gaPageUpdate } from '../slices/uiSlice';

import styled from 'styled-components';
const MobileMenu = styled(motion.div)`
    background-color: ${props => props.primaryColor ? props.primaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : 'blue'};
`;
const MenuNav = ({
    menu,
    menuItems,
    guideReady,
    adminPrimaryColor,
    primaryColor,
    logo,
    tipPage,

    menuAction,
    setNavData
}) => {

    const dispatch = useDispatch();

    const menuClickAction = (e) => {
        setNavData(e);
        dispatch(gaPageUpdate(e));
        setTimeout(() => {
            menuAction();
        }, 500);
    }

    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {menu &&
                    <MobileMenu id="mobile-menu" adminPrimaryColor={adminPrimaryColor} primaryColor={primaryColor} style={{ zIndex: 15, }} initial={'initial'} animate={'animate'} exit={'exit'} variants={menuMotion}>
                        <button id="menu-close" className="btn btn-none" onClick={menuAction}><FontAwesomeIcon icon={faTimes} /></button>
                        <div id="mobile-menu-container">
                            <div className="container text-left">
                                <div className="row">
                                    <div className="col-6 col-lg-4">
                                        <img className="img-fluid mb40" src={logo?.sourceUrl} srcSet={logo?.srcSet} alt={logo?.altText ? logo.altText : 'Company Logo'} />
                                    </div>
                                </div>
                                <div id="mobile-menu-main-links" className="row">
                                    <div className="col-12">
                                        <button className="first btn btn-link" onClick={() => {
                                            menuClickAction('cover');
                                            GAevent('Menu', 'Menu Link', 'Cover');
                                        }}>{menuItems.cover}</button>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-link" onClick={() => {
                                            menuClickAction('welcome');
                                            GAevent('Menu', 'Menu Link', 'Welcome');
                                        }}>{menuItems.welcome}</button>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-link" onClick={() => {
                                            menuClickAction('tours');
                                            GAevent('Menu', 'Menu Link', 'Tours');
                                        }}>{menuItems.tours}</button>
                                    </div>
                                    {menuItems.guide !== '' &&
                                        <div className="col-12">
                                            <button className="btn btn-link" onClick={() => {
                                                menuClickAction('guide');
                                                GAevent('Menu', 'Menu Link', 'Guide');
                                            }}>{menuItems.guide}</button>
                                        </div>
                                    }
                                    <div className="col-12">
                                        <button className="btn btn-link" onClick={() => {
                                            menuClickAction('stops');
                                            GAevent('Menu', 'Menu Link', 'Stops');
                                        }}>{menuItems.stops}</button>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-link" onClick={() => {
                                            menuClickAction('review');
                                            GAevent('Menu', 'Menu Link', 'Review');
                                        }}>{menuItems.review}</button>
                                    </div>
                                    {tipPage && menuItems.tip !== '' &&
                                        <div className="col-12">
                                            <button className="btn btn-link" onClick={() => {
                                                menuClickAction('tip');
                                                GAevent('Menu', 'Menu Link', 'Tip');
                                            }}>{menuItems.tip}</button>
                                        </div>
                                    }
                                    <div className="col-12">
                                        <button className="btn btn-link" onClick={() => {
                                            menuClickAction('thankyou');
                                            GAevent('Menu', 'Menu Link', 'Thank you');
                                        }}>{menuItems.close}</button>
                                    </div>
                                </div>
                                {/* <div id="mobile-menu-secondary-links" className="row">
                            <div className="col-6">
                                <button className="btn btn-link" href="/we-love-our-customers/">Our Customers</button>
                                <button className="btn btn-link" href="/team/">Meet the Team</button>
                                <button className="btn btn-link" href="/ngdpodcast/">Podcast</button>
                                <button className="btn btn-link" href="/blog/">Blog</button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-link" href="/payments/">Payments</button>
                                <button className="btn btn-link" href="/rewards/">Loyalty Rewards</button>
                                <button className="btn btn-link" href="/careers/">Careers</button>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </MobileMenu>
                }
            </AnimatePresence>
        </>
    )
}

export default MenuNav
