import React, { useState, useReducer, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faChevronRight, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as faCircleBorder } from "@fortawesome/pro-regular-svg-icons";

// component
import { Carousel } from 'react-bootstrap';

// actions
import { htmlDecode } from '../actions';


const SingleStop = ({
    singleStopData: { stopsPreviewImage, imageGallery, stopsHeading, stopsDescription, stopsFeaturedImage, stopsFeaturedHeading, stopsFeaturedDescription, stopsCouponOfferHeading, stopsCouponOfferDescription, stopsCouponOfferAdditionalInfo },
}) => {

    const [gallery, setGallery] = useState([]);
    const reducer = (state, action) => {
        switch (action.type) {
            case "Left": return (state !== gallery.length - 1) ? state + 1 : 0;
            case "Right": return (state !== 0) ? state - 1 : gallery.length - 1;
            case 'click': return action.value;
            case 'clear': return 0;
            default: return state;
        }
    }

    const [stopImagesIndex, dispatchSwipe] = useReducer(reducer, 0);

    // check for both imageGallery and stopsPreviewImage. imageGallery can be null on multiples stops
    useEffect(() => {
        if (Array.isArray(imageGallery)) {
            setGallery([stopsPreviewImage, ...imageGallery]);
        } else {
            setGallery([stopsPreviewImage]);
        }
        dispatchSwipe({ type: 'clear' })
    }, [imageGallery, stopsPreviewImage]);

    const [couponShow, setCouponShow] = useState(true);
    useEffect(() => {
        let couponShow = false;
        if (stopsCouponOfferHeading) {
            couponShow = true;
        } else if (stopsCouponOfferDescription) {
            couponShow = true;
        } else if (stopsCouponOfferAdditionalInfo) {
            couponShow = true;
        }
        setCouponShow(couponShow);
    }, [stopsCouponOfferHeading, stopsCouponOfferDescription, stopsCouponOfferAdditionalInfo]);


    const [highLight, setHighLight] = useState(true);
    useEffect(() => {
        let highLightShow = false;
        if (stopsFeaturedImage?.smallUrl) {
            highLightShow = true;
        } else if (stopsFeaturedHeading) {
            highLightShow = true;
        } else if (stopsFeaturedDescription) {
            highLightShow = true;
        }
        setHighLight(highLightShow);
    }, [stopsFeaturedImage, stopsFeaturedHeading, stopsFeaturedDescription]);

    return (
        <div className="container-fluid">
            {gallery &&
                <div id="carousel-container">
                    {gallery?.length > 1 &&
                        <div id="carousel-dir-container">
                            <div id="carousel-dir">
                                <div id="carousel-dir-left" onClick={() => dispatchSwipe({ type: 'Right' })}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </div>
                                <div id="carousel-dir-right" onClick={() => dispatchSwipe({ type: 'Left' })}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                            </div>
                        </div>
                    }

                    <Carousel activeIndex={stopImagesIndex} controls={false} indicators={false} >
                        {gallery?.map((item, index) =>
                            <Carousel.Item key={`stopImg-swipe-${index}`}>
                                <img width="750" height="800" className="img-fluid" src={item?.sourceUrl} srcSet={item?.srcSet} alt={item?.altText} />
                            </Carousel.Item>
                        )}
                    </Carousel>
                    {gallery.length > 1 &&
                        <div className="carousel-indicators-custom row justify-content-center no-gutters">
                            {gallery?.map((item, index) =>
                                <div className={`col-auto ${stopImagesIndex === index ? 'active' : ''} ${gallery.length - 1 === index ? 'last' : ''}`} onClick={() => dispatchSwipe({ type: 'click', value: index })} key={`carousel-indicator-${index}`}>
                                    {stopImagesIndex === index ?
                                        <FontAwesomeIcon icon={faCircle} />
                                        :
                                        <FontAwesomeIcon icon={faCircleBorder} />
                                    }
                                </div>

                            )}
                        </div>
                    }
                </div>
            }
            <div id="content-container">
                <div id="content-box">
                    <div className="row">
                        <div className="col">
                            <h4>{htmlDecode(stopsHeading)}</h4>
                            {stopsDescription && <p dangerouslySetInnerHTML={{ __html: stopsDescription }} />}
                        </div>
                    </div>
                </div>
            </div>
            {highLight &&
                <div className={`content-container ${stopsFeaturedImage?.largeSource && 'w-img'}`}>
                    {stopsFeaturedImage?.largeSource &&
                        <React.Fragment>
                            <img className="img-fluid d-block d-lg-none" width="600" height="338" src={stopsFeaturedImage?.smallUrl} alt={stopsFeaturedImage?.altText} />
                            <img className="img-fluid d-none d-lg-block" width="750" height="550" src={stopsFeaturedImage?.largeSource} alt={stopsFeaturedImage?.altText} />
                        </React.Fragment>
                    }
                    {(stopsFeaturedHeading || stopsFeaturedDescription) &&
                        <div className="content-box">
                            <div className="row">
                                <div className="col">
                                    {stopsFeaturedHeading && <h5 className={`${!stopsFeaturedDescription ? 'mb0' : ''}`}>{stopsFeaturedHeading}</h5>}
                                    {stopsFeaturedDescription && <p dangerouslySetInnerHTML={{ __html: stopsFeaturedDescription }} />}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            {couponShow &&
                <div className="content-container pt0">
                    <div className="content-box coupon-box">
                        <div className="coupon">
                            <div className="row">
                                <div className="col">
                                    {stopsCouponOfferHeading && <h5 className={`${(stopsCouponOfferAdditionalInfo || stopsCouponOfferAdditionalInfo) ? '' : 'mb0'}`}> {stopsCouponOfferHeading}</h5>}
                                    {stopsCouponOfferAdditionalInfo &&
                                        <div dangerouslySetInnerHTML={{ __html: stopsCouponOfferDescription }} />
                                    }
                                    {stopsCouponOfferAdditionalInfo &&
                                        <p className="text-center"><small>{stopsCouponOfferAdditionalInfo}</small></p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </div >
    )
}

export default SingleStop
