import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import FormData from 'form-data';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import styled from 'styled-components';

// action
import { setStopNav } from './slices/uiSlice';

const Btn = styled('button')`
    background-color: ${props => props.primaryColor ? props.primaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : '#6c757d'};
    border: 1px solid ${props => props.primaryColor ? props.primaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : '#6c757d'};
    &:hover{
		color: #fff;
        background-color: ${props => props.primaryColor ? props.primaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : '#6c757d'};
        &::before {
            background-color: rgba(0, 0, 0, 0.2);
	    }
    }
    &:disabled:hover::before{
        background-color: ${props => props.primaryColor ? props.primaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : '#6c757d'};
    }
`;

const Input = styled('input')`
    border-bottom-color: ${props => props.primaryColor ? props.primaryColor : props.adminPrimaryColor ? props.adminPrimaryColor : '#6c757d'};
`;

const GuestEmail = ({
    guidebookData,
    guideReady,
    inputGuideError,

    setInputGuide,
    setNavData,
}) => {

    const dispatch = useDispatch();
    const { adminPrimaryColor } = useSelector(state => state.data.admin);
    const { guidebookPrimaryColor: primaryColor } = useSelector(state => state.data.guidebooks);


    // guest access field
    const inputReducer = (state, action) => {
        switch (action.type) {
            case 'value': return action.value;
            default: return state;
        }
    }
    const [inputField, dispatchInputField] = useReducer(inputReducer, '');


    const [showEmailCapture, setShowEmailCapture] = useState(true);
    // email validation
    const checkEmail = (value) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = re.test(String(value).toLowerCase()) || value === '';
        let error = valid ? '' : 'Email not valid';
        dispatchEmailField({ type: 'check', valid, value, error });
    }
    // email reducer
    const emailReducer = (state, action) => {
        switch (action.type) {
            case 'value': return { ...state, value: action.value };
            case 'sent': return { ...state, sent: action.value, message: action.message };
            case 'error': return { ...state, error: action.message };
            case 'valid': return { ...state, valid: action.valid };
            case 'load': return { ...state, loading: action.value };
            case 'check': return { valid: action.valid, value: action.value, error: action.error };
            default: return state;
        }
    }
    // email data
    const [emailField, dispatchEmailField] = useReducer(emailReducer, { valid: true, value: '', error: '', sent: false, message: '', loading: false });
    // send email
    const sendEmailCapture = (value) => {
        let contactForm = new FormData();
        contactForm.append('your-email', value);
        let formId = guidebookData?.templateGuideEmailFormId;
        formId = formId.split('id="')[1].split('"')[0];
        dispatchEmailField({ type: 'load', value: true });
        axios.post(`${process.env.REACT_APP_API}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`, contactForm, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
                dispatchEmailField({ type: 'sent', value: true, message: guidebookData?.templateGuideEmailSuccessMessage });
                sessionStorage.setItem('emailCapture', JSON.stringify(`true`));
                dispatchEmailField({ type: 'load', value: false });
                setTimeout(() => {
                    setNavData('welcome');
                }, 3000);

            })
            .catch((err) => {
                dispatchEmailField({ type: 'error', error: err.data.message });
            });
    }

    const formSubmission = () => {
        // form submitted wait for guide data to be pulled
        if (!guideReady) {
            setInputGuide(inputField);
        } else {
            // guide data pulled submit email
            if (showEmailCapture) { sendEmailCapture(emailField.value); }
        }
    }

    useEffect(() => {
        // stop navbar if guide data is required and not yet pulled
        if (guidebookData.guidebookGuideid && !guideReady) {
            dispatch(setStopNav(true));
        } else {
            dispatch(setStopNav(false));
        }
    }, [guideReady]);

    // set emailField to be valid because its not needed
    useEffect(() => {
        if (guidebookData?.templateGuideEmailFormId === null) {
            dispatchEmailField({ type: 'valid', valid: true });
            setShowEmailCapture(false);
        }
    }, [guidebookData?.templateGuideEmailFormId])

    // send email after guide data pulled
    useEffect(() => {
        if (emailField.valid && emailField.value && showEmailCapture) {
            sendEmailCapture(emailField.value);
        } else if (guideReady && !showEmailCapture) {
            dispatchEmailField({ type: 'sent', value: true, message: guidebookData?.templateGuideEmailSuccessMessage });
            setTimeout(() => {
                setNavData('welcome');
            }, 2000);
        }
    }, [guideReady]);

    return (
        <>
            <div className="container-fluid">
                <div className="content-container">
                    <div id="content-box">
                        <div className="row">
                            <div className="col">
                                <h2>{guidebookData?.templateGuideEmailHeading}</h2>
                                <div dangerouslySetInnerHTML={{ __html: guidebookData?.templateGuideEmailDesc }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {!emailField.sent ?
                                    <form className="row justify-content-center" onSubmit={(e) => {
                                        e.preventDefault();
                                        formSubmission();
                                    }}>
                                        {guidebookData?.guidebookGuideid &&
                                            <div className="col-12 mb20 access-code">
                                                <Input id="guide-input" className="form-control" type="text" adminPrimaryColor={adminPrimaryColor} primaryColor={primaryColor} onChange={(e) => {
                                                    dispatchInputField({
                                                        type: 'value', value: e.currentTarget.value
                                                    })
                                                }} value={inputField} placeholder="Enter access code here" />
                                                {inputGuideError && <p className="form-error mb0">{inputGuideError}</p>}
                                            </div>
                                        }

                                        {showEmailCapture &&
                                            <div className="col-12 mb20 email-field">
                                                <Input id="email-input" type="email" className="form-control" adminPrimaryColor={adminPrimaryColor} primaryColor={primaryColor} onChange={(e) => {
                                                    dispatchEmailField({ type: 'value', value: e.currentTarget.value });
                                                    checkEmail(e.currentTarget.value);
                                                }} value={emailField.value} placeholder="Enter your email address" />
                                                {emailField.error && <p className="form-error mb0">{emailField.error}</p>}
                                            </div>
                                        }

                                        <div className="col-12 submit-field">
                                            <Btn className="btn btn-submit" type="submit" adminPrimaryColor={adminPrimaryColor} primaryColor={primaryColor} disabled={(!guideReady && inputField === '') || !emailField.valid}>
                                                {emailField.loading ?
                                                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                                                    :
                                                    (
                                                        <p className="mb0">Enter Guidebook <FontAwesomeIcon icon={faChevronRight} /></p>
                                                    )
                                                }
                                            </Btn>
                                        </div>
                                    </form>
                                    :
                                    <div className="row justify-content-center text-center">
                                        <div className="col-12 col-lg-6">
                                            <p className="form-error mb0">{emailField.message}</p>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GuestEmail
